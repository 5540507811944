:root {

  --besalco-fontWeights-bold: 700;
  --besalco-fontSizes-sm: 0.875rem;
  --besalco-colors-gray-400: #A0AEC0;
  --besalco-fontWeights-semibold: 600;
  --besalco-fontSizes-lg: 1.125rem;
  --besalco-colors-gray-700: #1f2733;
  --besalco-colors-gray-200: #E2E8F0;

  --besalco-blue: rgb(0,60,125,1);
  --besalco-grey-third: #7d7d7d;  
  --besalco-grey-second: #d1d1d1;
  --besalco-grey-first: #eaeaea;
  --besalco-grey-light: #f5f5f5;
  --besalco-font-family: 'Verdana', 'Helvetica';
  --besalco-table-font-size: 11px;
  --besalco-table-font-color: black;
  --besalco-table-font-color-contrast: white;
  --besalco-table-background-color-main: white;
  --besalco-table-background-color-second: #d1d1d1;
  --besalco-table-background-color-third: #7d7d7d;
  --besalco-table-header-background-color: rgb(0,60,125,1);
  --besalco-table-header-font-weight: 500;
  --besalco-table-hover-color: #c8dfed;
  --besalco-scroll-bar: rgb(170, 174, 187);
  --besalco-red-lose: #bf2e33;
  --besalco-red-danger-smooth: #ff5454;

  --besalco-sidebar-width: 250px;
  --besalco-sidebar-width-collapsed: 70px;
  /* --besalco-sidebar-bg-color: #f8f9fa; */
  --besalco-sidebar-bg-color: transparent;
  --besalco-sidebar-vertical-border-color: #cbd0dd;
  --besalco-navbar-vertical-font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --besalco-navbar-vertical-link-color: #525b75;
  --besalco-navbar-vertical-link-hover-color: #3e465b;
  --besalco-navbar-vertical-link-active-color: #3e465b;
  --besalco-navbar-vertical-link-hover-bg-color: #e0e2e7;
  --besalco-navbar-vertical-group-title-color: #6e7891;
  --besalco-navbar-hr-line-color: #6e7891;
  --besalco-navbar-collapse-button-border-color: #6e7891;
}

.bg-besalco {
  /* --bs-bg-opacity: 1; */
  background-color: var(--besalco-blue) !important;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--besalco-grey-third);
  opacity: 0.7; /* Firefox */
}

.form-floating>label {
  color: var(--besalco-grey-third);
  opacity: 0.7; /* Firefox */
}

.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
  opacity: .65;
  transform: scale(.85) translateY(-.9rem) translateX(0.15px);
}

.besalco-branding-wrap {
  height: 65px;
  margin-top: 25px;
  margin-left: 10px;
}

.bista-branding-wrap {
  height: 39px;
  margin-top: -5px;
  margin-left: 0px;
}

body {
  font-family: var(--besalco-font-family,var(--bs-font-sans-serif));
  overflow-y: hidden;
}

.kill-shadow {
  box-shadow: unset!important;
}

.besalco-app-navbar {
  background-color: var(--besalco-blue);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--besalco-table-background-color-third);
}

.besalco-sidebar {
  background-color: var(--besalco-grey-light);
  border-color: var(--besalco-grey-second);
  border-width: 1px;
  border-style: solid;
}

.besalco-dash-title {
  color: var(--besalco-blue);
  font-weight: 700;
}

.table.table-besalco {
  font-size: var(--besalco-table-font-size);
  color: var(--besalco-table-font-color);
  background-color: var(--besalco-table-background-color-main);
}

.table.table-besalco thead tr th {
  font-size: var(--besalco-table-font-size);
  font-weight: var(--besalco-table-header-font-weight);
  color: var(--besalco-table-font-color-contrast);
  background-color: var(--besalco-table-header-background-color);
  border-color: var(--besalco-table-background-color-third);
  text-align: center;
}

.table.table-besalco.table-striped>tbody>tr:nth-of-type(odd)>* {
  /* background-color: var(--besalco-table-header-background-color-second); */
  color: var(--besalco-table-font-color);  
}

.table.table-besalco.table-hover tbody tr:hover td {
  background: var(--besalco-table-hover-color)
}
.bg-grey-light {
  --bs-bg-opacity: 1;
  background-color: var(--besalco-grey-light)!important;
}

.bg-grey-first {
  --bs-bg-opacity: 1;
  background-color: var(--besalco-grey-first)!important;
}

.bg-grey-second {
  --bs-bg-opacity: 1;
  background-color: var(--besalco-grey-second)!important;
}

.bg-grey-third {
  --bs-bg-opacity: 1;
  background-color: var(--besalco-grey-third)!important;
}

.btn-primary {
  --bs-btn-bg: var(--besalco-blue);
  --bs-btn-border-color: var(--besalco-blue);    
  --bs-btn-disabled-bg: var(--besalco-grey-third);
  --bs-btn-disabled-border-color: var(--besalco-grey-third);
}

.btn-outline-primary {
  /* --bs-btn-bg: var(--besalco-blue); */
  --bs-btn-border-color: var(--besalco-blue);
  --bs-btn-hover-bg: var(--besalco-blue);
  --bs-btn-color: var(--besalco-blue);
  --bs-btn-border-color: var(--besalco-blue);
  --bs-btn-hover-bg: var(--besalco-blue);
  --bs-btn-hover-border-color: var(--besalco-blue);
  --bs-btn-active-bg: var(--besalco-blue);
  --bs-btn-active-border-color: var(--besalco-blue);
  --bs-btn-disabled-color: var(--besalco-blue);
  --bs-btn-disabled-border-color: var(--besalco-blue);
}

.panel {
  border-radius: 1px;
  border: solid;
  border-color: #e8e8e8;
  border-width: 1px;
  box-shadow: 7px 12px 18.94px -19.94px rgba(4,26,55,0.16);
}

.card {
  box-shadow: none!important;
}

.card.chart-card {
  /* border-style: none;
  border-radius: 5px;
  border: none;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1); */

  border-radius: 1px;
  border: solid;
  border-color: #e8e8e8;
  border-width: 1px;
  /* box-shadow: 7px 12px 18.94px -19.94px rgba(4,26,55,0.16); */
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1)
}

.loading {
  color: var(--besalco-blue);
  font-weight: 500;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(5, end) 1500ms infinite;
  animation: ellipsis steps(5, end) 1500ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}

.loadingtext {
  opacity: 0;
  animation: example1 20s cubic-bezier(.4,0,.2,1) 10s infinite;
}

@keyframes example1 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateX(-60%);
  }
  55% {
    opacity: 1;
    transform: translateX(-60%);
  }
  to {
    opacity: 0;
  }
}

.picktostart {
  opacity: 0;
  animation: picktostartani 15s cubic-bezier(.4,0,.2,1) 1s infinite;
}

@keyframes picktostartani {
  0% {
    opacity: 0;
  }
  39% {
    opacity: 1;
    transform: translateY(60%);
  }
  60% {
    opacity: 1;
    transform: translatey(-100%);
  }
  to {
    opacity: 0;
  }
}



.project-tab {
  padding: 10%;
  margin-top: -8%;
}
.project-tab #tabs{
  background: #007b5e;
  color: #eee;
}
.project-tab #tabs h6.section-title{
  color: #eee;
}
.project-tab #tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: var(--besalco-blue);
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 3px solid !important;
  font-size: 16px;
  font-weight: bold;
}
.project-tab .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  color: var(--besalco-blue);
  font-size: 16px;
  font-weight: 600;
}
.project-tab .nav-link:hover {
  border: none;
}
.project-tab thead{
  background: #f3f3f3;
  color: #333;
}
.project-tab a{
  text-decoration: none;
  color: #333;
  font-weight: 600;
}

.nav-pills .nav-link {
  padding-top: 0.25rem;
  padding-left: 0.5rem;
  padding-bottom: 0.25rem;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--besalco-blue);
}

.modal-70w {
  max-width: none!important;
  width: 70%;
}

.modal-80w {
  max-width: none!important;
  width: 80%;
}

.modal-85w {
  max-width: none!important;
  width: 85%;
}

.modal-90w {
  max-width: none!important;
  width: 90%;
}

.modal-97w {
  max-width: none!important;
  width: 97%;
}

.modal-header {
  padding-top: 10px;
  padding-bottom: 0px;
}

.main-background {
  background-color: #fbfbfb;
}

.col-xs-8r,
.col-sm-8r,
.col-md-8r,
.col-lg-8r {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-8r {
  width: 12.5%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-8r {
      width: 12.5%;
      float: left;
  }
}

@media (min-width: 992px) {
  .col-md-8r {
      width: 12.5%;
      float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-8r {
      width: 12.5%;
      float: left;
  }
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--besalco-scroll-bar);
  border-radius: 5px;
  border: transparent;
}

pre {
  white-space: pre-wrap; /* Para mantener los saltos de línea */
  word-break: break-all; /* Romper palabras en cualquier carácter */
}

.mt-6 {
  margin-top: 5rem !important;
}