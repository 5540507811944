

a {
-webkit-transition: .3s all ease;
-o-transition: .3s all ease;
transition: .3s all ease; }
a:hover {
    text-decoration: none !important; }

.content {
padding: 7rem 0; }


.half, .half .container > .row {
height: 100vh;
min-height: 700px; }

.half .contents {
background: #f6f7fc; }

.half .bg {
width: 25%; }
@media (max-width: 767.98px) {
    .half .bg {
    width: 100%;
    height: 200px; } }

.half .contents {
    background-image: url("../background-login.jpg");
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%; }
    @media (max-width: 767.98px) {
        .half .contents {
        width: 100%; } }

.half .contents .form-control, .half .bg .form-control {
border: none;
border-radius: 4px;
height: 54px;
background: #efefef; }
.half .contents .form-control:active, .half .contents .form-control:focus, .half .bg .form-control:active, .half .bg .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none; }

.half .bg {
background-size: cover;
background-position: center; }

.half label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.half .form-group {
    margin-bottom: 1rem;
}

.half a {
color: #888;
text-decoration: underline; }

.half .btn {
width: 100%;
height: 54px;
padding-left: 30px;
padding-right: 30px; }

.half .forgot-pass {
position: relative;
top: 2px;
font-size: 14px; }

.form-block {
background: #fff;
padding: 40px;
max-width: 400px;
-webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2); }
@media (max-width: 767.98px) {
    .form-block {
    padding: 25px; } }

.control {
display: block;
position: relative;
padding-left: 30px;
margin-bottom: 15px;
cursor: pointer;
font-size: 14px; }
.control .caption {
    position: relative;
    top: .2rem;
    color: #888; }

.control input {
position: absolute;
z-index: -1;
opacity: 0; }

.control__indicator {
position: absolute;
top: 2px;
left: 0;
height: 20px;
width: 20px;
background: #e6e6e6;
border-radius: 4px; }

.control--radio .control__indicator {
border-radius: 50%; }

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
background: #ccc; }

.control input:checked ~ .control__indicator {
background: #fb771a; }

.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
background: #fb8633; }

.control input:disabled ~ .control__indicator {
background: #e6e6e6;
opacity: 0.9;
pointer-events: none; }

.control__indicator:after {
font-family: 'icomoon';
content: '\e5ca';
position: absolute;
display: none;
font-size: 16px;
-webkit-transition: .3s all ease;
-o-transition: .3s all ease;
transition: .3s all ease; }

.control input:checked ~ .control__indicator:after {
display: block;
color: #fff; }

.control--checkbox .control__indicator:after {
top: 50%;
left: 50%;
margin-top: -1px;
-webkit-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%); }

.control--checkbox input:disabled ~ .control__indicator:after {
border-color: #7b7b7b; }

.control--checkbox input:disabled:checked ~ .control__indicator {
background-color: #7e0cf5;
opacity: .2; }


  /* Logos de Besalco */

  .logo_wrapper2 {
    line-height: 26px;
    -webkit-font-smoothing: antialiased;
    color: #494949;
    font-size: 16px;
    font-weight: 300;
    font-family: 'Outfit', sans-serif;
    background: 0 0;
    border: 0;
    margin: 0;
    padding: 0;
    /* vertical-align: baseline; */
    outline: 0;
    float: none;
    height: 80px;
    margin-left: 0px;
    padding-top: 0px;
  }
  
  .q_logo {
      top: 50%;
      left: 0;
  }
  
  .q_logo a {
      display: block;
      visibility: hidden;
      text-decoration: none;
  }
  
  .q_logo img {
      top: -50%;
      width: auto!important;
      max-width: none;
      transition: opacity .6s ease-in-out;
  }
  